import React from 'react';

import Layout from '../components/Layout/layout';
import SEO from '../components/SEO/seo';
import AboutUs from '../components/About';

const AboutPage = () => (
  <Layout>
        <SEO
            title="Taxi Near Eindhoven Airport - Taxionspot.Nl"
            meta="Taxi Near Eindhoven Airport - Taxionspot.Nl"
            description="taxionspot - If you need a taxi, go to Taxi near Eindhoven airport,
            call one of the following taxi services, telephone number +310402405999"
        />
    <AboutUs />
  </Layout>
);

export default AboutPage;
